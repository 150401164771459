import React from "react"
import { Container, Grid, Header } from "semantic-ui-react"
import { useStaticQuery, graphql } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import "../layout.css"

const AboutArtesanal = ({ intl }) => {
  const { img } = useStaticQuery(graphql`
    {
      img: file(relativePath: { eq: "about_artesanal2.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 760) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Container
      fluid
      content
      style={{ backgroundColor: "#CBA06D", minHeight: "50vh" }}
    >
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center" width={9}>
            <Header
              style={{ padding: "2em" }}
              className="title"
              as="h1"
              content={intl.formatMessage({ id: "artesanal.title" })}
            />
            <Container text textAlign="justified">
              <p style={{ fontFamily: "Glacial", fontSize: "1.6em" }}>
                {intl.formatMessage({ id: "artesanal.info" })}
              </p>
            </Container>
          </Grid.Column>
          <Grid.Column style={{ padding: "2.5em" }} width={7}>
            <Parallax y={[0, 20]} tagOuter="figure">
              <Img
                fluid={img.childImageSharp.fluid}
                alt="collage"
                width={500}
              />
            </Parallax>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default injectIntl(AboutArtesanal)
