import React from "react"
import { Container, Grid, Header } from "semantic-ui-react"
import "../layout.css"
import { injectIntl } from "gatsby-plugin-intl"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Menu = ({ intl }) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "carta" } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  console.log(allFile)
  return (
    <Container
      fluid
      content
      style={{ backgroundColor: "#CBA06D", minHeight: "95vh" }}
      id="#CartaArtesanal"
    >
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
            <Header
              style={{ padding: "2em" }}
              className="title"
              as="h1"
              content={intl.formatMessage({ id: "artesanal.menu_title" })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Carousel infiniteLoop showThumbs={false} showStatus={false}>
              {allFile.nodes.map(({ id, name, childImageSharp }) => {
                return <Img alt={name} key={id} fluid={childImageSharp.fluid} 
                width={600}
                height={400}/>
              })}
            </Carousel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default injectIntl(Menu)
