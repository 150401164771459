import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Background } from "../components/sections"
import AboutArtesanal from "../components/sections/aboutArtesanal"
import Menu from "../components/sections/menu"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Map } from "../components/map"
import { ParallaxProvider } from "react-scroll-parallax"
import { injectIntl } from "gatsby-plugin-intl"
import { Container, Grid, Header } from "semantic-ui-react"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

const SecondPage = ({ intl }) => {
  const { img } = useStaticQuery(graphql`
    {
      img: file(relativePath: { eq: "banner_artesanal.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <ParallaxProvider>
        <SEO title="Artesanal" />
        <Background img={img} />
        <AboutArtesanal />
        <Menu id="#CartaArtesanal"/>
        <Container
          fluid
          content
          textAlign="center"
          style={{ backgroundColor: "#CBA06D", minHeight: "95vh" }}
        >
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <Header
                  style={{ padding: "2em" }}
                  className="title"
                  as="h1"
                  content={intl.formatMessage({ id: "artesanal.map_title" })}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={6}>
                <Header
                  style={{ padding: "0.2em", marginTop: "1em" }}
                  className="title"
                  as="h1"
                  content="Artesanal Pereira"
                />
                <Parallax y={[0, 20]} tagOuter="figure">
                  <Img
                    fluid={img.childImageSharp.fluid}
                    alt="collage"
                    width={500}
                  />
                </Parallax>
              </Grid.Column>
              <Grid.Column width={10}>
                <Map
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA_hz9RMtDw3Y7OMgUbyrJpCwC6j-ZZ_00&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  ubi={{ lat: 4.794719, lng: -75.69211 }}
                  title="Artesanal Coffee"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </ParallaxProvider>
    </Layout>
  )
}

export default injectIntl(SecondPage)
